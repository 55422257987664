import { useTranslation } from "react-i18next";
import { DayOfWeek, DayRestriction } from "../commom/pricing";

const Weekends = {
  Saturday: DayOfWeek.SATURDAY,
  Sunday: DayOfWeek.SUNDAY
};
// function to check if an array contains a specific value
const contains = (arr: DayRestriction[], day: DayOfWeek): boolean => {
  return arr.some((item) => item.day_of_week === day);
};

// function to convert DayOfWeek to a short string
const shortDay = (day: DayOfWeek, lang: string): string => {
  switch (day) {
    case DayOfWeek.MONDAY:
      return lang === "en" ? "Mon" : "Mo";
    case DayOfWeek.TUESDAY:
      return lang === "en" ? "Tue" : "Di";
    case DayOfWeek.WEDNESDAY:
      return lang === "en" ? "Wed" : "Mi";
    case DayOfWeek.THURSDAY:
      return lang === "en" ? "Thu" : "Do";
    case DayOfWeek.FRIDAY:
      return lang === "en" ? "Fri" : "Fr";
    case DayOfWeek.SATURDAY:
      return lang === "en" ? "Sat" : "Sa";
    case DayOfWeek.SUNDAY:
      return lang === "en" ? "Sun" : "So";
    default:
      return "";
  }
};

// DisplayDay function
const displayDay = (d: DayRestriction[], lang: string): string => {
  if (d.length === 0) {
    if (lang === "en") {
      return "Every day";
    } else {
      return "Täglich";
    }
  }

  if (d.length === 2) {
    if (contains(d, Weekends.Saturday) && contains(d, Weekends.Sunday)) {
      if (lang === "en") {
        return "Weekends";
      } else {
        return "Wochenenden";
      }
    }
  }

  if (d.length === 5) {
    if (!contains(d, Weekends.Saturday) && !contains(d, Weekends.Sunday)) {
      if (lang === "en") {
        return "Weekdays";
      } else {
        return "Wochentage";
      }
    }
  }

  return d
    .map((v, i) => {
      return shortDay(v.day_of_week, lang) + (i !== d.length - 1 ? ", " : "");
    })
    .join("");
};
const DayDisplay: React.FC<{ restrictions: DayRestriction[] }> = ({ restrictions }) => {
  const { i18n } = useTranslation(["days"]);

  return <>{displayDay(restrictions, i18n.language)}</>;
};

export default DayDisplay;
