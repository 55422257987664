import React, { useContext, useEffect, useState } from "react";
import { paramProps } from "../commom/types";
import { AppCtx } from "../commom/app.context";
import { useTranslation } from "react-i18next";
import PricingPopup from "../popups/pricing.popup";
import Skeleton from "react-loading-skeleton";
import { PriceDetails, PriceObj } from "../commom/pricing";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faClock } from "@fortawesome/free-solid-svg-icons";
import DayDisplay from "./display_day.component";

const PriceComponent: React.FC<paramProps> = ({ evseID, children }) => {
  const { t, i18n } = useTranslation(["common", "price", "days"]);

  const [flatPrice, setFlatPrice] = useState<PriceDetails | null>(null);
  const [timePrices, setTimePrices] = useState<PriceObj[] | null>(null);
  const [energyPrice, setEnergyPrice] = useState<PriceDetails | null>(null);
  const [blockingFee, setBlockingFee] = useState<PriceDetails | null>(null);
  const [durationMessage, setDurationMessage] = useState<string>("");

  const [idleDurationThreshold, setIdleDurationThreshold] = useState<{
    hours: number;
    minutes: number;
    seconds: number;
  }>({ hours: 0, minutes: 0, seconds: 0 });

  const [idleDurationRate, setIdleDurationRate] = useState<string>("");

  //
  const [timer, setTimer] = useState("");

  const appCtx = useContext(AppCtx);

  useEffect(() => {
    // setTimer
    const interval = setInterval(() => {
      const now = new Date();
      if (appCtx?.price?.content?.valid_until === undefined) return;

      const validUntil = new Date(appCtx?.price?.content?.valid_until);
      // show timer in minute:seconds format
      // calculate the difference between now and validUntil
      // and show a countdown in minute and seconds separated by a colon
      // for example 18:59
      const diff = validUntil.getTime() - now.getTime();

      if (diff <= 0) {
        // set the diff to 0
        // reload page
        // wait for 1 second before reload
        setTimeout(() => {
          window.location.reload();
        }, 2000);
        setTimer("00:00");
        return;
      }
      const minutes = Math.floor(diff / 60000);
      const seconds = Math.floor((diff % 60000) / 1000);
      // append 0 if seconds is less than 10
      let secondString = seconds.toString();
      let minuteString = minutes.toString();
      if (seconds < 10) {
        secondString = "0" + seconds;
      }
      if (minutes < 10) {
        minuteString = "0" + minutes;
      }

      setTimer(minuteString + ":" + secondString);
    }, 1000);

    return () => clearInterval(interval);
  }, [appCtx?.price?.content?.valid_until]);

  useEffect(() => {
    if (appCtx?.price?.status === 200) {
      const activePrice = appCtx?.price?.content?.active_price;
      const allPrices = appCtx?.price?.content?.all_prices;

      if (activePrice?.flat_price !== undefined && activePrice?.flat_price !== null) {
        setFlatPrice(activePrice.flat_price);
      }

      if (activePrice?.energy_price !== undefined && activePrice?.energy_price !== null) {
        setEnergyPrice(activePrice.energy_price);
      }

      if (activePrice?.blocking_fees !== undefined && activePrice?.blocking_fees !== null) {
        setBlockingFee(activePrice.blocking_fees);

        const message = generateIdleFeeMessage(activePrice?.blocking_fees);
        console.log(message);
      }

      if (allPrices && activePrice?.time_prices !== undefined && activePrice?.time_prices !== null) {
        setTimePrices(allPrices.filter((price) => price.type === "TIME"));
      }
    } else {
      // Handle price error if needed
    }
  }, [i18n.language, appCtx?.price?.content]);

  function parseTime(timeString: string) {
    const [hours, minutes, seconds] = timeString.split(":").map(Number);
    return { hours, minutes, seconds };
  }

  function generateIdleFeeMessage(durationPrices: PriceDetails) {
    const idleFeeRate = durationPrices.price.toLocaleString("de-DE", {
      style: "currency",
      currency: "EUR"
    });

    if (durationPrices.min_duration === undefined) return "";
    const idleDurationThreshold = parseTime(durationPrices.min_duration as string);

    if (idleDurationThreshold.hours === 0 && idleDurationThreshold.minutes === 0) return "";

    let durationMessageText = "";
    if (idleDurationThreshold.hours !== 0) {
      durationMessageText += idleDurationThreshold.hours + " ";
      if (durationMessageText === "1 ") {
        durationMessageText += t("price:hour");
      } else {
        durationMessageText += t("price:hours");
      }
    }

    if (idleDurationThreshold.hours !== 0 && idleDurationThreshold.minutes !== 0) {
      durationMessageText += " " + t("common:and") + " ";
    }

    if (idleDurationThreshold.minutes !== 0) {
      durationMessageText += " " + idleDurationThreshold.minutes + " " + t("price:minutes");
    }

    setDurationMessage(durationMessageText);
    setIdleDurationRate(idleFeeRate);
    setIdleDurationThreshold(idleDurationThreshold);
  }

  //

  return (
    <>
      <div className="flex flex-col">
        {flatPrice === null || flatPrice === undefined ? (
          <Skeleton count={1} className="animate-pulse" />
        ) : (
          <div>
            {flatPrice.price.toLocaleString("de-DE", {
              style: "currency",
              currency: "EUR"
            }) +
              " " +
              t("price:fee")}
          </div>
        )}

        {energyPrice !== null ? (
          <div>
            {energyPrice.price.toLocaleString("de-DE", {
              style: "currency",
              currency: "EUR"
            }) +
              " " +
              t("price:energy")}
          </div>
        ) : (
          <Skeleton count={1} className="animate-pulse" />
        )}

        {timePrices?.map((price, key) => (
          <div className="flex justify-start last:pb-1 gap-x-2" key={"f2" + key}>
            <span key={"s" + key}>
              {price.price.toLocaleString("de-DE", {
                style: "currency",
                currency: "EUR"
              }) +
                " " +
                t("price:minute")}
            </span>

            {price?.day_restrictions ? (
              <h6 key={"f4" + key}>
                <FontAwesomeIcon icon={faCalendar} /> <DayDisplay restrictions={price.day_restrictions} />
              </h6>
            ) : (
              <></>
            )}

            {price?.time_restriction?.start_time && price?.time_restriction?.stop_time ? (
              <h6 className="text-slate-800 dark:text-white" key={"f5" + key}>
                <FontAwesomeIcon icon={faClock} /> {price.time_restriction?.start_time} - {price.time_restriction?.stop_time}
              </h6>
            ) : (
              <></>
            )}
          </div>
        ))}

        <div className="flexflex-row">
          {blockingFee?.price && blockingFee !== null ? (
            <>
              {/*  <div>
                {blockingFee.price.toLocaleString("de-DE", {
                  style: "currency",
                  currency: "EUR"
                }) +
                  " " +
                  t("price:blocking_fee_title")}
              </div>*/}

              {idleDurationRate !== "" && (idleDurationThreshold.hours !== 0 || idleDurationThreshold.minutes !== 0) ? (
                <div className="mt-1">
                  {t("price:blocking_fee_duration", {
                    time: durationMessage,
                    price: idleDurationRate
                  })}
                </div>
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}
        </div>

        {appCtx?.price?.content?.valid_until ? (
          <div className="text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400" role="alert">
            <div>
              <span className="font-medium"> {t("price:alert")} </span>
              {t("price:change_due")}
            </div>

            <div>
              {t("price:valid_until", {
                time: getHour(appCtx?.price?.content?.valid_until)
              })}
              &nbsp;
              {timer
                ? t("price:countdown", {
                    timer: timer
                  })
                : ""}
            </div>
          </div>
        ) : (
          ""
        )}

        <PricingPopup />
      </div>
    </>
  );
};

export default PriceComponent;

function getHour(time: string | undefined) {
  // example return 18:59  from 2024-04-29 18:59:59
  if (time === undefined) return "";
  return time.split(" ")[1].split(":")[0] + ":" + time.split(" ")[1].split(":")[1];
}
