import React, { useContext, useEffect, useState } from "react";
import { AppCtx } from "../commom/app.context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faClock, faStopwatch } from "@fortawesome/free-solid-svg-icons";
import { PriceObj } from "../commom/pricing";
import DayDisplay from "../components/display_day.component";
import { useTranslation } from "react-i18next";

const PricingPopup: React.FC = () => {
  const { t } = useTranslation(["common", "price"]);

  const [showModal, setShowModal] = React.useState(false);

  // const { t, i18n } = useTranslation(["common", "price"]);

  const [flatPrices, setFlatPrices] = useState<PriceObj[] | null>(null);
  const [energyPrices, setEnergyPrices] = useState<PriceObj[] | null>(null);
  const [timePrices, setTimePrices] = useState<PriceObj[] | null>(null);
  const [blockingFees, setBlockingFees] = useState<PriceObj | null>(null);

  const appCtx = useContext(AppCtx);

  useEffect(() => {
    try {
      // get the adhoc text from the app context
      if (!appCtx?.price?.content?.all_prices) {
        return;
      }

      setFlatPrices(null);
      setEnergyPrices(null);
      setTimePrices(null);
      setBlockingFees(null);

      const allPrices = appCtx?.price?.content?.all_prices;

      allPrices.forEach((price) => {
        switch (price.type) {
          case "FLAT":
            setFlatPrices((prevFlatPrices) => {
              return prevFlatPrices ? [...prevFlatPrices, price] : [price];
            });
            break;

          case "ENERGY":
            setEnergyPrices((prevEnergyPrices) => {
              return prevEnergyPrices ? [...prevEnergyPrices, price] : [price];
            });
            break;

          case "TIME":
            setTimePrices((prevTimePrices) => {
              return prevTimePrices ? [...prevTimePrices, price] : [price];
            });
            break;

          case "BLOCKING_FEE":
            setBlockingFees(price);
            break;

          default:
            break;
        }
      });
    } catch (e) {
      console.log(e);
    }
  }, [appCtx?.price?.content?.all_prices]);

  const handleClick = async (val: boolean) => {
    setShowModal(val);
  };

  const timeParse = (timeString: string | undefined) => {
    if (timeString === undefined) {
      return "";
    }
    const [hours, minutes] = timeString.split(":").map(Number);

    return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;
  };

  function parseTime(timeString: string) {
    const [hours, minutes, seconds] = timeString.split(":").map(Number);
    return { hours, minutes, seconds };
  }

  const parseDurationTime = (duration: string | undefined) => {
    if (duration === undefined) return "";
    const idleDurationThreshold = parseTime(duration as string);

    if (idleDurationThreshold.hours === 0 && idleDurationThreshold.minutes === 0) return "";

    let durationMessageText = "";
    if (idleDurationThreshold.hours !== 0) {
      durationMessageText += idleDurationThreshold.hours + " ";
      if (durationMessageText === "1 ") {
        durationMessageText += t("price:hour");
      } else {
        durationMessageText += t("price:hours");
      }
    }

    if (idleDurationThreshold.hours !== 0 && idleDurationThreshold.minutes !== 0) {
      durationMessageText += " " + t("common:and") + " ";
    }

    if (idleDurationThreshold.minutes !== 0) {
      durationMessageText += " " + idleDurationThreshold.minutes + " " + t("price:minutes");
    }
    return durationMessageText;
  };
  return (
    <>
      {appCtx?.connector?.cpoName === undefined ? (
        <button
          type="button"
          className="px-3  text-xs font-bold text-center inline-flex items-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        ></button>
      ) : (
        <button type="button" onClick={() => handleClick(true)} className="py-2 text-blue-500">
          {t("price:view_more_button")}
        </button>
      )}

      {showModal ? (
        <>
          <div
            id="defaultModal"
            aria-hidden="true"
            className="mt-2 max-w-lg overflow-y-auto overflow-x-hidden fixed right-0 left-0 top-4 z-50 justify-center items-center md:inset-0 h-modal sm:h-full"
          >
            <div className="relative px-4 w-full max-w-2xl h-full md:h-auto">
              <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                <div className="flex justify-between items-start p-5 rounded-t border-b dark:border-gray-600">
                  <h3 className="text-xl font-semibold text-gray-900 lg:text-2xl dark:text-white">{t("price:pricing_details_title")}</h3>
                  <button
                    type="button"
                    onClick={() => setShowModal(false)}
                    className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                    data-modal-toggle="defaultModal"
                  >
                    <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </div>
                {/*<div className="p-6 space-y-6">*/}
                {/*  <div className="relative flex flex-col my-6 bg-white dark:bg-gray-700 dark:text-white shadow-sm border border-slate-200 dark:border-slate-700 rounded-lg w-96">*/}
                <div className="p-4">
                  <div className="mb-4 flex items-center justify-between">
                    <h5 className="text-slate-800 dark:text-white text-lg font-semibold">{t("price:flat_fee_title")}</h5>
                  </div>
                  {flatPrices?.map((price, key) => (
                    <div className="divide-y divide-slate-200" key={"d1" + key}>
                      <div className="flex items-center justify-between pb-3 pt-3 last:pb-0" key={"d2" + key}>
                        <div className="flex items-center gap-x-3" key={"d3" + key}>
                          <div key={"d4" + key}>
                            {price?.day_restrictions ? (
                              <h6 className="text-slate-800 dark:text-white" key={"d5" + key}>
                                <FontAwesomeIcon icon={faCalendar} /> <DayDisplay restrictions={price.day_restrictions} />
                              </h6>
                            ) : (
                              <></>
                            )}

                            {price?.time_restriction?.start_time && price?.time_restriction?.stop_time ? (
                              <h6 className="text-slate-800 dark:text-white" key={"d6" + key}>
                                <FontAwesomeIcon icon={faClock} /> {timeParse(price.time_restriction?.start_time)} -{" "}
                                {timeParse(price.time_restriction?.stop_time)}
                              </h6>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                        <h6 className="text-slate-600 dark:text-white font-medium" key={"d7" + key}>
                          {price.price.toLocaleString("de-DE", {
                            style: "currency",
                            currency: "EUR"
                          })}
                        </h6>
                      </div>
                    </div>
                  ))}
                </div>

                {energyPrices && energyPrices?.length > 0 ? (
                  <div className="p-4">
                    <div className="mb-4 flex items-center justify-between">
                      <h5 className="text-slate-800 dark:text-white text-lg font-semibold">{t("price:energy_fee_title")}</h5>
                    </div>
                    {energyPrices?.map((price, key) => (
                      <div className="divide-y divide-slate-200" key={"e1" + key}>
                        <div className="flex items-center justify-between pb-3 pt-3 last:pb-0" key={"e2" + key}>
                          <div className="flex items-center gap-x-3" key={"e3" + key}>
                            <div key={"e4" + key}>
                              {price?.day_restrictions ? (
                                <h6 className="text-slate-800 dark:text-white" key={"e5" + key}>
                                  <FontAwesomeIcon icon={faCalendar} /> <DayDisplay restrictions={price.day_restrictions} />
                                </h6>
                              ) : (
                                <></>
                              )}

                              {price?.time_restriction?.start_time && price?.time_restriction?.stop_time ? (
                                <h6 className="text-slate-800 dark:text-white" key={"e6" + key}>
                                  <FontAwesomeIcon icon={faClock} /> {timeParse(price.time_restriction?.start_time)} -{" "}
                                  {timeParse(price.time_restriction?.stop_time)}
                                </h6>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                          <h6 className="text-slate-600 dark:text-white font-medium" key={"e7" + key}>
                            {price.price.toLocaleString("de-DE", {
                              style: "currency",
                              currency: "EUR"
                            })}
                          </h6>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <></>
                )}

                {timePrices && timePrices?.length > 0 ? (
                  <div className="p-4">
                    <div className="mb-4 flex items-center justify-between">
                      <h5 className="text-slate-800 text-lg font-semibold dark:text-white">{t("price:time_fee_title")}</h5>
                    </div>
                    {timePrices?.map((price, key) => (
                      <div className="divide-y divide-slate-200" key={"f1" + key}>
                        <div className="flex items-center justify-between pb-3 pt-3 last:pb-0" key={"f2" + key}>
                          <div className="flex items-center gap-x-3" key={"f3" + key}>
                            <div>
                              {price?.day_restrictions ? (
                                <h6 className="text-slate-800 dark:text-white" key={"f4" + key}>
                                  <FontAwesomeIcon icon={faCalendar} /> <DayDisplay restrictions={price.day_restrictions} />
                                </h6>
                              ) : (
                                <></>
                              )}

                              {price?.time_restriction?.start_time && price?.time_restriction?.stop_time ? (
                                <h6 className="text-slate-800 dark:text-white" key={"f5" + key}>
                                  <FontAwesomeIcon icon={faClock} /> {timeParse(price.time_restriction?.start_time)} -{" "}
                                  {timeParse(price.time_restriction?.stop_time)}
                                </h6>
                              ) : (
                                <></>
                              )}

                              {price?.duration_restriction?.max_duration ? (
                                <h6 className="text-slate-800 dark:text-white" key={"f6" + key}>
                                  <FontAwesomeIcon icon={faStopwatch} /> {t("price:max_duration_title")}:{" "}
                                  {parseDurationTime(price.duration_restriction?.max_duration)}
                                </h6>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                          <h6 className="text-slate-600 dark:text-white font-medium">
                            {price.price.toLocaleString("de-DE", {
                              style: "currency",
                              currency: "EUR"
                            })}
                          </h6>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <></>
                )}

                {blockingFees ? (
                  <div className="p-4">
                    <div className="mb-4 flex items-center justify-between">
                      <h5 className="text-slate-800 dark:text-white text-lg font-semibold">{t("price:blocking_fee_title")}</h5>
                    </div>
                    <div className="divide-y divide-slate-200">
                      <div className="flex items-center justify-between pb-3 pt-3 last:pb-0">
                        <div className="flex items-center gap-x-3">
                          <div>
                            {blockingFees?.day_restrictions ? (
                              <h6 className="text-slate-800 dark:text-white">
                                <FontAwesomeIcon icon={faCalendar} /> <DayDisplay restrictions={blockingFees.day_restrictions} />
                              </h6>
                            ) : (
                              <></>
                            )}

                            {blockingFees?.time_restriction?.start_time && blockingFees?.time_restriction?.stop_time ? (
                              <h6 className="text-slate-800 dark:text-white">
                                <FontAwesomeIcon icon={faClock} /> {timeParse(blockingFees.time_restriction?.start_time)} -{" "}
                                {timeParse(blockingFees.time_restriction?.stop_time)}
                              </h6>
                            ) : (
                              <></>
                            )}

                            {blockingFees?.duration_restriction?.min_duration ? (
                              <h6 className="text-slate-800 dark:text-white">
                                <FontAwesomeIcon icon={faStopwatch} />{" "}
                                {blockingFees.duration_restriction?.min_duration
                                  ? t("price:min_duration_title") +
                                    ": " +
                                    parseDurationTime(blockingFees.duration_restriction?.min_duration)
                                  : ""}
                              </h6>
                            ) : (
                              <></>
                            )}
                            {blockingFees?.duration_restriction?.max_duration ? (
                              <h6 className="text-slate-800 dark:text-white">
                                <FontAwesomeIcon icon={faStopwatch} />{" "}
                                {blockingFees.duration_restriction?.max_duration
                                  ? t("price:max_duration_title") +
                                    ": " +
                                    parseDurationTime(blockingFees.duration_restriction?.max_duration)
                                  : ""}
                              </h6>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                        <h6 className="text-slate-600 dark:text-white font-medium">
                          {blockingFees?.price.toLocaleString("de-DE", {
                            style: "currency",
                            currency: "EUR"
                          })}
                        </h6>
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                <div className="flex items-center p-6 space-x-2 rounded-b border-t border-gray-200 dark:border-gray-600">
                  <button
                    data-modal-toggle="defaultModal"
                    type="button"
                    onClick={() => setShowModal(false)}
                    className="px-3 py-2 text-xs font-medium text-center inline-flex items-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  >
                    Ok
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};
export default PricingPopup;
